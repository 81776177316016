import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Specification from './elements/Specification';
import styles from './Characteristics.module.css';

import type {
  ProductCharacteristicData,
  ProductParameterGroupData,
  GroupPlace,
  ProductData,
} from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

export interface CharacteristicsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  groups: ProductParameterGroupData[];
  product: ProductData;
}

export type ProductGroups = Record<GroupPlace, ProductParameterGroupData[]>;

type SizeData = {
  value: string;
  unit: string;
};

const Characteristics: FC<CharacteristicsProps> = (props) => {
  const { groups, product, ...restProps } = props;
  const { parameterValues, parameters, units } = product;

  return (
    <div {...restProps}>
      <ul className={styles.list}>
        {groups.map((group) => {
          const currentParameters = parameters.filter((targetParameter) => {
            return targetParameter.groupId === group.id;
          });

          switch (group.theme) {
            case 'sizes': {
              const sizes: SizeData[] = [];
              currentParameters.forEach((parameter) => {
                const parameterValue = parameterValues.find((pv) => {
                  return pv.parameterId === parameter.id;
                });
                const unit = units.find((u) => u.id === parameterValue.unitId);
                const value = parameterValue.value.map((item) => parseFloat(item)).join(', ');

                sizes.push({ value, unit: unit.title });
              });

              const isSimilarUnit = sizes.every((size) => sizes[0].unit === size.unit);
              const sizeText = isSimilarUnit
                ? `${sizes.map((elem) => elem.value).join(' x ')}${sizes[0].unit}`
                : sizes.map((elem) => `${elem.value}${elem.unit}`).join(' х ');
              return (
                <Specification key={group.title} className={styles.parameter} title={group.title}>
                  <span className={cn(styles.characteristicValue, styles.sizeValue)}>
                    {sizeText}
                  </span>
                </Specification>
              );
            }
            case 'default':
            case 'hardness': {
              const characteristics: ProductCharacteristicData[] = [];
              currentParameters.forEach((parameter) => {
                const value = parameterValues.find((v) => v.parameterId === parameter.id);
                const unit = units.find((u) => u.id === value.unitId);

                characteristics.push({
                  name: parameter.title,
                  value: value.value,
                  icon: value.icon,
                  unit: unit && unit.title ? unit.title : '',
                });
              });

              return (
                <Specification
                  key={group.title}
                  title={`${characteristics.map((item) => item.name)}`}
                  className={cn(styles.parameter, {
                    [styles.themeHardness]: group.theme === 'hardness',
                  })}
                >
                  <div className={styles.characteristic}>
                    {characteristics.map(
                      (item) =>
                        item.icon && (
                          <img key={item.icon} className={styles.icon} src={item.icon} alt='' />
                        ),
                    )}
                    <span className={styles.characteristicValue}>
                      {characteristics.map((item) =>
                        item.value
                          .map((value) => (item.unit ? `${value} ${item.unit}` : value))
                          .join(', '),
                      )}
                    </span>
                  </div>
                </Specification>
              );
            }
            default: {
              return null;
            }
          }
        })}
      </ul>
    </div>
  );
};

export default memo(Characteristics);
