import { memo, useCallback } from 'react';
import { IconLike } from '@divlab/divanui/icons';

import * as ModalWindows from '@Stores/ModalWindows';
import { useCompare } from '@Queries/Compare';
import useFavouriteModal from '@Hooks/useFavouriteModal';

import type { RemoveEventData } from '@Events/listeners/compare-listener';
import type { FC } from 'react';
import type { ProductData } from '@Types/Product';
import type { LikeProps } from '@divlab/divanui/icons';

export interface ProductLikeProps extends LikeProps {
  product: ProductData;
  hash?: string;
  label?: string;
}

const ProductLike: FC<ProductLikeProps> = (props) => {
  const { product, hash, label, onClick, ...restProps } = props;
  const productHash = hash || product?.hash;
  const { isInCompare, add, remove } = useCompare(product.id, productHash);
  const { shouldModalOpen, disableModalOpen } = useFavouriteModal();

  const handleClick = useCallback(
    (e) => {
      const eventData: RemoveEventData = {
        productId: product.id,
        payload: {
          analytics: { product, label },
          hash: productHash,
          parameterValues: product?.constructorParameterValues,
        },
      };
      if (!eventData.payload.hash) delete eventData.payload.hash;
      if (isInCompare) {
        remove(eventData);
      } else {
        add(eventData);
        if (shouldModalOpen) ModalWindows.open('FavoritesAuth', { disableModalOpen });
      }

      if (onClick) onClick(e);
    },
    [
      product,
      label,
      productHash,
      isInCompare,
      onClick,
      remove,
      add,
      shouldModalOpen,
      disableModalOpen,
    ],
  );

  return <IconLike {...restProps} liked={isInCompare} onClick={handleClick} />;
};

export default memo(ProductLike);
