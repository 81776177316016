import ProductNoImage from '@Components/ProductNoImage';
import Img from '@UI/Img';

import type { Orientation } from '@Types/Base';
import type { FC, ReactElement } from 'react';
import type { ImgProps } from '@UI/Img';

export interface ProductImageProps extends ImgProps {
  orientation?: Orientation;
  ImgFallback?: () => ReactElement;
}

const ProductImage: FC<ProductImageProps> = (props) => {
  const { src, cnImage, className, orientation, ImgFallback, ...restProps } = props;

  if (!src && ImgFallback) {
    return <ImgFallback />;
  }

  if (!src) {
    return <ProductNoImage {...restProps} orientation={orientation} className={className} />;
  }

  return <Img {...restProps} src={src} cnImage={cnImage} className={className} />;
};

export default ProductImage;
