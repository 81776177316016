import { createStore, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';

interface SubscriptionsData {
  subscriptions: number[];
}

const subscriptionsStore = createStore<SubscriptionsData>(() => {
  update(subscriptionsStore, () => ({
    subscriptions: [],
  }));
});

export const useSubscriptions = () => {
  const store = useStore(subscriptionsStore);
  return store.subscriptions;
};

export const setSubscription = (productId: number) => {
  update(subscriptionsStore, (prevStore) => ({
    subscriptions: [...prevStore.subscriptions, productId],
  }));
};
