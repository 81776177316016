import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import placeholder from './placeholder.svg';
import styles from './ProductNoImage.module.css';

import type { Orientation } from '@Types/Base';
import type { FC, HTMLAttributes } from 'react';

export interface ProductNoImageProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  orientation?: Orientation;
}

const ProductNoImage: FC<ProductNoImageProps> = (props) => {
  const { className, orientation, ...restProps } = props;

  return (
    <Img
      {...restProps}
      className={cn(
        styles.noImage,
        {
          [styles.landscape]: orientation === 'landscape',
          [styles.portrait]: orientation === 'portrait',
        },
        className,
      )}
      src={placeholder}
      cnImage={styles.image}
    />
  );
};

export default memo(ProductNoImage);
