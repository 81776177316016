import { memo } from 'react';
import { cn, Price } from '@divlab/divanui';
import { IconChevronRight } from '@divlab/divanui/icons';

import Img from '@UI/Img';
import useTranslation from '@Queries/useTranslation';
import styles from './CreditTab.module.css';

import type { CreditData } from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

export interface CreditTabProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  credit: CreditData;
  showIcon?: boolean;
  text?: string;
  textEnd?: string;
  view?: 'catalog' | 'detail';
}

const CreditTab: FC<CreditTabProps> = (props) => {
  const { t } = useTranslation();
  const {
    credit,
    text,
    textEnd = t('ui.per-month'),
    view = 'detail',
    className,
    showIcon = true,
    ...restProps
  } = props;

  return (
    <div
      {...restProps}
      className={cn(
        styles.container,
        {
          [styles.viewCatalog]: view === 'catalog',
          [styles.viewDetail]: view === 'detail',
        },
        className,
      )}
    >
      {credit.icon?.src && showIcon && (
        <div
          className={styles.iconWrapper}
          style={{ width: `${credit.icon.width}px`, height: `${credit.icon.height}px` }}
        >
          <Img
            className={styles.iconImg}
            width={credit.icon.width}
            height={credit.icon.height}
            cnImage={styles.image}
            src={credit.icon.src}
            alt=''
          />
        </div>
      )}
      <div className={styles.text}>
        {text ? `${text} ` : ''}{' '}
        {credit.price && <Price price={credit.price} className={styles.textBold} />}
        {!!textEnd && <span className={cn(styles.textBold, styles.textGray)}>{textEnd}</span>}
      </div>
      <IconChevronRight className={styles.arrow} />
    </div>
  );
};

export default memo(CreditTab);
