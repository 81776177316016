import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '@divlab/divanui';

import styles from './Specification.module.css';

import type { FC, HTMLAttributes } from 'react';

export interface SpecificationProps extends HTMLAttributes<HTMLElement> {
  className?: string;
  title: string;
}

const Specification: FC<SpecificationProps> = (props) => {
  const { title, children, ...restProps } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [isHidden, setIsHidden] = useState(false);

  const handleResize = useCallback(() => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();

    // скрывает промежуточный элемент, когда выводится одна черточка
    setIsHidden(rect.width < 7);
  }, []);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <li {...restProps}>
      <span className={cn(styles.title, 'Specifications')}>{title}</span>
      <div ref={ref} className={cn(styles.bgc, { [styles.hidden]: isHidden })}></div>
      {children}
    </li>
  );
};

export default memo(Specification);
