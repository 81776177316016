import { cn } from '@divlab/divanui';

import useTranslation from '@Queries/useTranslation';
import IconClock from './elements/IconClock';
import styles from './OnSaleSoonTab.module.css';

import type { FC } from 'react';

interface OnSaleSoonTabProps {
  className?: string;
  showIcon?: boolean;
}

const OnSaleSoonTab: FC<OnSaleSoonTabProps> = (props) => {
  const { className, showIcon } = props;
  const { t } = useTranslation();

  return (
    <div className={cn(styles.main, className)}>
      {showIcon && <IconClock className={styles.icon} />} {t('ui.coming-soon-tab')}
    </div>
  );
};

export default OnSaleSoonTab;
