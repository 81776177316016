import { useCallback } from 'react';
import { cn } from '@divlab/divanui';

import * as SubscriptionsStore from '@Stores/Subscriptions';
import useTranslation from '@Queries/useTranslation';
import * as ModalWindows from '@Stores/ModalWindows';
import Button from '@UI/Button';
import IconSubscribe from './elements/IconSubscribe';
import styles from './AboutReceiptButton.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { ProductData } from '@Types/Product';

interface AboutReceiptButtonProps extends HTMLAttributes<HTMLDivElement> {
  product: ProductData;
  isCircleView?: boolean;
  cnText?: string;
}

const AboutReceiptButton: FC<AboutReceiptButtonProps> = (props) => {
  const { product, isCircleView, cnText, className, ...restProps } = props;
  const { t } = useTranslation();
  const subscriptions = SubscriptionsStore.useSubscriptions();
  const subscribed = subscriptions.some((subscription) => subscription === product.id);

  const saveProduct = useCallback(() => {
    SubscriptionsStore.setSubscription(product.id);
  }, [product.id]);

  const handleClick = useCallback(() => {
    ModalWindows.open('NotifyAboutReceiptNew', { product, onSubscribed: saveProduct });
  }, [product, saveProduct]);

  return subscribed ? (
    <Button
      {...restProps}
      view={isCircleView ? 'circle' : 'main'}
      cnText={cn(styles.text, cnText)}
      theme='transparent'
      className={cn(styles.subscribed, styles.bordered, className)}
      before={
        !isCircleView && (
          <div className={styles.icon}>
            <IconSubscribe isActive className={styles.iconSubscribe} />
          </div>
        )
      }
    >
      {isCircleView ? <IconSubscribe isActive /> : t('ui.subscribed')}
    </Button>
  ) : (
    <Button
      {...restProps}
      view={isCircleView ? 'circle' : 'main'}
      cnText={cn(styles.text, cnText)}
      theme='secondary'
      onClick={handleClick}
      className={className}
    >
      {isCircleView ? (
        <IconSubscribe className={styles.iconSubscribe} />
      ) : (
        t('ui.inform-about-receipt')
      )}
    </Button>
  );
};

export default AboutReceiptButton;
