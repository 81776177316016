import { memo } from 'react';
import { cn } from '@divlab/divanui';

import Tag from './elements/Tag';
import styles from './ProductTags.module.css';

import type { ProductTagData } from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

export interface ProductTagsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  tags: ProductTagData[];
  showTagInfo?: boolean;
  cardView?: boolean;
}

const ProductTags: FC<ProductTagsProps> = (props) => {
  const { className, tags, showTagInfo, cardView, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.tags, className)}>
      {tags.map((tag, index) => (
        <Tag
          className={styles.tag}
          tag={tag}
          showTagInfo={showTagInfo}
          key={index}
          cardView={cardView}
        />
      ))}
    </div>
  );
};

export default memo(ProductTags);
