import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './Tag.module.css';
import useMedias from '@Hooks/useMedias';

import type { ProductTagData } from '@Types/Product';
import type { FC, HTMLAttributes } from 'react';

export interface TagsProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  tag: ProductTagData;
  showTagInfo?: boolean;
  cardView?: boolean;
}

const Tag: FC<TagsProps> = (props) => {
  const { className, tag, showTagInfo, cardView, ...restProps } = props;
  const [infoPosition, setInfoPosition] = useState({});
  const { isMobileM } = useMedias();
  const refInfo = useRef(null);

  const moveInfo = useCallback(() => {
    if (refInfo.current) {
      const { right } = refInfo.current.getBoundingClientRect();

      if (right > window.innerWidth) {
        setInfoPosition({
          left: `${window.innerWidth - right - 5}px`,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (showTagInfo && isMobileM) {
      moveInfo();
    }

    if (showTagInfo && !isMobileM) {
      setInfoPosition({});
    }
  }, [isMobileM, moveInfo, showTagInfo]);

  return (
    <div {...restProps} className={cn(styles.tag, { [styles.cardView]: cardView }, className)}>
      <Img className={styles.image} src={tag.image.src} alt={tag.image.alt} />

      {showTagInfo && (
        <div
          className={styles.info}
          dangerouslySetInnerHTML={{ __html: tag.title }}
          style={infoPosition}
          ref={refInfo}
        />
      )}
    </div>
  );
};

export default memo(Tag);
