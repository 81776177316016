import type { FC, SVGAttributes } from 'react';

interface IconSubscribeProps extends SVGAttributes<SVGSVGElement> {
  isActive?: boolean;
}

const IconSubscribe: FC<IconSubscribeProps> = (props) => {
  const { isActive, ...restProps } = props;
  return (
    <svg
      {...restProps}
      width='26'
      height='26'
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.91286 3.80645L13.0214 3L15.3529 3.40323L17.2957 5.01613L18.85 7.43548L19.2386 10.6613L19.75 14.129L21.25 17L21.75 18H5.70714H4.75L6.80429 14.129L7.19286 12.6774V9.85484V7.43548L8.35857 5.01613L9.91286 3.80645Z'
        fill={isActive ? '#404040' : '#f5f3f1'}
      />
      <path
        d='M16.1952 18.611C16.1952 19.4214 15.8732 20.1986 15.3002 20.7716C14.7272 21.3447 13.95 21.6666 13.1396 21.6666C12.3292 21.6666 11.552 21.3447 10.979 20.7716C10.406 20.1986 10.0841 19.4214 10.0841 18.611M10.0841 18.611H5.18036C4.53984 18.611 4.18373 17.8701 4.58387 17.3699L5.14002 16.6747C6.11525 15.4557 6.64655 13.9411 6.64655 12.38V9.82631C6.64655 6.24029 9.55359 3.33325 13.1396 3.33325C16.7256 3.33325 19.6327 6.24029 19.6327 9.82631V12.38C19.6327 13.9411 20.164 15.4557 21.1392 16.6747L21.6953 17.3699C22.0955 17.8701 21.7394 18.611 21.0988 18.611H10.0841Z'
        stroke='#404040'
        strokeWidth='1.5'
      />
    </svg>
  );
};

export default IconSubscribe;
