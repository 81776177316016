import { cn, Price } from '@divlab/divanui';

import Img from '@UI/Img';
import styles from './YandexSplitCreditTab.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CreditData } from '@Types/Product';

export interface YandexSplitCreditTabProps extends HTMLAttributes<HTMLDivElement> {
  credit: CreditData;
}

const YandexSplitCreditTab: FC<YandexSplitCreditTabProps> = (props) => {
  const { credit, className, ...restProps } = props;

  return (
    <div {...restProps} className={cn(styles.creditTabWrapper, className)}>
      <div className={styles.priceContainer}>
        <div
          className={styles.iconWrapper}
          style={{ width: `${credit.icon.width}px`, height: `${credit.icon.height}px` }}
        >
          <Img
            className={styles.iconImg}
            width={credit.icon.width}
            height={credit.icon.height}
            cnImage={styles.image}
            src={credit.icon.src}
            alt=''
          />
        </div>
        <Price price={credit.price} className={styles.price} />
      </div>

      <div>{credit.prefix}</div>
    </div>
  );
};

export default YandexSplitCreditTab;
