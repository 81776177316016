import { memo, useCallback } from 'react';
import { SampleOption, Select } from '@divlab/divanui';

import Link from '@Navigation/Link';
import styles from './MainSelect.module.css';

import type { PropsWithChildren, FC, MouseEvent } from 'react';
import type { SelectItemData, SelectProps } from '@divlab/divanui';

export interface ContainerProps {
  className?: string;
  item: SelectItemData;
}

const Container: FC<PropsWithChildren<ContainerProps>> = (props) => {
  const { item, ...restProps } = props;

  return item.href ? (
    <Link {...restProps} to={item.href} className={styles.option} />
  ) : (
    <div {...restProps} className={styles.option} />
  );
};

export interface MainSelectProps extends SelectProps {
  onClickItem?: (e: MouseEvent, item: SelectItemData) => void;
}

const MainSelect: FC<MainSelectProps> = (props) => {
  const { items, mode, onClickItem, ...restProps } = props;

  const handleClickItem = useCallback(
    (e: MouseEvent, item: SelectItemData) => {
      if (onClickItem) onClickItem(e, item);
    },
    [onClickItem],
  );

  return (
    <Select
      {...restProps}
      defaultChecked={items.find((option: SelectItemData) => option.selected)}
      items={items}
      mode={mode || 'single-required'}
      renderItem={(itemProps: SelectItemData) => {
        return (
          <Container
            item={itemProps}
            data-testid={items.find((item) => item.id === itemProps.id).testId}
          >
            <SampleOption
              item={itemProps}
              onClick={(e: MouseEvent) => handleClickItem(e, itemProps)}
            />
          </Container>
        );
      }}
    />
  );
};

export default memo(MainSelect);
