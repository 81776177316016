import type { FC, SVGAttributes } from 'react';

interface IconClockProps extends SVGAttributes<SVGSVGElement> {
  className?: string;
}

const IconClock: FC<IconClockProps> = (props) => {
  const { ...restProps } = props;

  return (
    <svg
      {...restProps}
      width='17'
      height='18'
      viewBox='0 0 17 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.29536 5.14279L8.43852 9.42911C8.47586 9.50378 8.55218 9.55095 8.63566 9.55095H12.3566M16.2137 8.99993C16.2137 13.2604 12.7599 16.7142 8.49944 16.7142C4.23896 16.7142 0.785156 13.2604 0.785156 8.99993C0.785156 4.73945 4.23896 1.28564 8.49944 1.28564C12.7599 1.28564 16.2137 4.73945 16.2137 8.99993Z'
        stroke='#F5F3F1'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default IconClock;
